









import MultiplePatientSelectInput from '@/components/inputs/MultiplePatientSelectInput.vue';
import SupervisorSelectInput from '@/components/inputs/SupervisorSelectInput.vue';
import { Client, Site } from '@/models';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouter } from '@/router';

export default defineComponent({
  components: { MultiplePatientSelectInput, SupervisorSelectInput },
  name: 'ClientSupervisorsPage',
  setup() {
    const { route } = useRouter();

    const client = ref<Client | null>(null);
    const sites = ref<Site[]>([]);

    const loading = ref(false);

    async function fetchClient(): Promise<void> {
      loading.value = true;
      client.value = await Client.find(route.value.params.uuid);
      sites.value = await client.value.sites().append('primaryPractitioner').with('supervisors').get();
      loading.value = false;
    }

    onMounted(() => fetchClient());

    return {
      client,
      sites,
      loading,
    };
  },
});
