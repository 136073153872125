



















import { Patient, Practitioner } from '@/models';

import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  name: 'MultiplePatientSelectInput',

  props: {
    value: {
      type: Object as PropType<Patient | null>,
      required: false,
    },
    practitioner: {
      type: Object as PropType<Practitioner | null>,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const patients = ref<Patient[]>([]);
    const search = ref(null);
    const loading = ref(false);

    const patient = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    const rules = props.required ? [(v: Patient | null): string | boolean => !!v || 'Patient is required'] : [];

    const fetchPatients = async (): Promise<void> => {
      const target = props.practitioner ? props.practitioner.patients() : Patient.builder();
      loading.value = true;
      patients.value = await target.where('name', search.value).get();
      loading.value = false;
    };

    const searchPatients = debouncedSearch(() => fetchPatients());

    watch(search, () => searchPatients(), { immediate: true });
    watch(
      () => props.value,
      (value?: Patient | null) => {
        console.log(value);
        if (value === null) {
          search.value = null;
        }
      }
    );

    return {
      patients,
      search,
      patient,
      loading,
      rules,
    };
  },
});
