





















import { Patient, Site } from '@/models';

import { PropType, computed, defineComponent, onMounted, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';

export default defineComponent({
  name: 'MultiplePatientSelectInput',

  props: {
    value: {
      type: Array as PropType<Patient[]>,
      required: false,
    },
    site: {
      type: Object as PropType<Site>,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const patients = ref<Patient[]>([]);
    const search = ref<string | null>(null);
    const loading = ref(false);
    const supervisors = ref<Patient[]>([]);

    // const supervisors = computed({
    //   get: () => props.site.supervisors,
    //   set: (item: Patient[]) => setSupervisor(item),
    // });

    const patient = computed({
      get: () => props.value,
      set: (value) => emit('input', value),
    });

    // const rules = props.required ? [(v: Patient | null): string | boolean => !!v || 'Patient is required'] : [];

    const fetchPatients = async (): Promise<void> => {
      loading.value = true;
      patients.value = await props.site.patients().where('name', search.value).get();
      loading.value = false;
    };

    const fetchSupervisors = async (): Promise<void> => {
      loading.value = true;
      supervisors.value = await props.site.supervisors().get();
      loading.value = false;
    };

    const searchPatients = debouncedSearch(() => fetchPatients());

    const setSupervisor = (supervisors: Patient[]) => {
      props.site.updateRaw({
        supervisors: supervisors.map((item) => item.uuid),
      });
    };

    onMounted(() => {
      const eagerSupervisors = props.site.eagerSupervisors ?? [];
      if (eagerSupervisors.length > 0) {
        patients.value.push(...eagerSupervisors);
      }
    });

    watch(search, async () => {
      fetchSupervisors();
      searchPatients();
    });
    // watch(
    //   () => props.value,
    //   (value?: Patient | null) => {
    //     console.log(value);
    //     if (value === null) {
    //       search.value = null;
    //     }
    //   }
    // );

    return {
      patients,
      search,
      patient,
      loading,
      supervisors,
      setSupervisor,
      // rules,
    };
  },
});
